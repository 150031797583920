* {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
  /* scroll-behavior: smooth; */
}

/* Theming react-data-grid */
.rdg {
  --rdg-background-color: var(--theme-neutral-bg-1) !important;
  --rdg-row-hover-background-color: var(--theme-neutral-bg-2) !important;
  --rdg-header-background-color: var(--theme-neutral-bg-1) !important;
  --rdg-row-selected-background-color: var(--theme-info-bg-1) !important;
  --rdg-grid-inline-size: 0 !important;
}

/* html {
  font-size: 19px;
} */

body {
  background-color: var(--theme-neutral-bg-2);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* Hide scrollbar for Chrome, Safari and Opera */

body::-webkit-scrollbar {
  display: none;
}
.MuiTableContainer-root::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.cs-one {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}
.cs-01 {
  font-size: 26px;
  font-weight: 500;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.themeColor {
  background-color: #3565ea;
}

.cs-02 {
  font-size: 20px;
}
input:checked:before {
  background-color: red !important;
}
.cs-03 {
  height: 40vh;
  margin-top: 80px;
  margin-bottom: 80px;
}
.cs-04 {
  background-color: #3565ea;
}
.cs-06 {
  margin-top: 140px;
  text-align: center;
}
.bdrAndRds {
  border: 1px solid #dedede;
  border-radius: 6px;
}
.bdrBtmWhite {
  border-bottom: 1px solid white;
}

.bdrBtmGrey {
  border-bottom: 1px solid #dedede;
}
.color-white {
  color: white;
}

.imageStyle {
  width: 80%;
  height: 100%;
}

.font-18 {
  font-weight: 300;
  font-size: 18px;
}
.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.priceTag {
  font-size: 32px;
  font-weight: 500;
}

.minLineHeight {
  line-height: 0;
}

.customBtn {
  background-color: white;
  outline: 0;
  border-radius: 4px;
  padding: 10px 20px;
  border: 1px solid #dedede;
  margin-top: 20px;
}

.cs-padding {
  padding: 80px;
}

.imageTop {
  top: 10px;
}

@media only screen and (max-width: 480px) {
  .mobileMargin {
    margin-top: -3rem;
  }
  .cs-06 {
    margin-top: 20px;
  }
  .cs-padding {
    padding: 30px;
  }
  .imageStyle {
    width: 80%;
    height: 100%;
    /* margin: 20px; */
  }
  .sm-hide {
    display: none !important;
  }
  .imageTop {
    top: 50px;
  }
  .cs-05 {
    margin-top: 4rem;
  }
  .cs-01 {
    font-size: 18px;
  }
  .cs-02 {
    font-size: 16px;
  }
  .fontHeader {
    font-size: 14px;
  }
  .font-18 {
    font-size: 12px;
  }
  .font-20 {
    font-size: 12px;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background: #ffbd39 !important;
}
/* .rdrDayNumber span {
  color: var(--theme-neutral-text-1) !important;
} */

.rdrMonth {
  margin: auto;
}
.rdrDayPassive .rdrDayNumber span {
  color: var(--theme-neutral-bg-4) !important;
}

.allDays {
  /* display: none; */
  position: absolute;
  top: 100%;
  left: -40px;
  width: 150px;
  padding: 5px;
  z-index: 100;
  background: #ffbd39;
  color: #fff;
}
.allDays::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 41%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffbd39 transparent;
}
.endDay {
  /* display: none; */
  position: absolute;
  top: 100%;
  right: 0px;
  width: 150px;
  padding: 5px;
  z-index: 100;
  background: #ffbd39;
  color: #fff;
}
.endDay::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffbd39 transparent;
}
/* @media only screen and (max-width: 991px) {
  .imageStyle,.imageTop{
    width: 100%;
    padding: 0;
  }
} */

@media only screen and (min-width: 768px) {
  :root {
    --ec-height: 50px;
    --ec-mainWidth: 300px;
    --ec-calendarWidth: 500px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .allDays {
    left: -20px;
  }
  .allDays::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 26%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ffbd39 transparent;
  }
}

/* Theming react-contextmenu */

/* .react-contextmenu-wrapper { */
/* display: contents; */
/* } */
/* .react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin-block-start: 2px;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  min-inline-size: 160px;
  outline: none;
  opacity: 0;
  padding-block: 5px;
  padding-inline: 0;
  pointer-events: none;
  text-align: start;
  transition: opacity 250ms ease !important;
}
.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}
.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding-block: 3px;
  padding-inline: 20px;
  text-align: inherit;
  white-space: nowrap;
}
.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}
.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}
.react-contextmenu-item--divider {
  border-block-end: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-block-end: 3px;
  padding-block: 2px;
  padding-inline: 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}
.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}
.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item::after {
  content: "▶";
  display: inline-block;
  position: absolute;
  inset-inline-end: 7px;
}
.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
} */
.pac-container {
  z-index: 99999;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  /* border-radius: 50%; */
  padding: 10px;
  text-align: center;
  border-radius: 12px;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 96px;
  height: 28px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

.rdrDayNumber span {
  color: var(--theme-neutral-text-1) !important;
}
