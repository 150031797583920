.main {
  padding: 20px 50px 50px 90px;
  color: "white";
}

a:hover {
  text-decoration: none;
}
.mr-auto:hover {
  text-decoration: none;
}

.heading {
  padding: 35px;
  font-size: 25px;
}

.user {
  float: right;
  font-size: 16px;
  padding: 20px;
}

.mr-auto {
  color: "white";
  text-align: center;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.dropdown {
  display: none;
}
.brand {
  display: none;
  font-size: 12px;
}

.logo {
  display: none;
}

@media screen and (max-width: 766px) {
  .upper {
    display: none;
  }

  .logo,
  .brand,
  .dropdown {
    display: inline;
  }
}
.sideNavGradient {
  background: linear-gradient(
    180deg,
    rgba(255, 246, 240, 0.38) 0%,
    rgba(251, 232, 233, 0.31) 57.44%,
    rgba(252, 252, 255, 0.57) 100%
  );
}

.gradient {
  height: 70px;
  background: linear-gradient(
      63.77deg,
      rgba(255, 189, 57, 0.46) 60.9%,
      rgba(255, 202, 97, 0.46) 52.13%
    ),
    linear-gradient(128.42deg, #fffdc3 24.93%, rgba(255, 255, 255, 0) 72.12%);
  filter: blur(279px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
